<template>
  <div id="app" class="bg-body">
    <make-header
      :minor="$route.meta.minor"
      v-if="!$route.meta.customHeader"
    ></make-header>
    <keep-alive>
      <router-view
        :key="$route.fullPath"
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <router-view
      :key="$route.fullPath"
      v-if="!$route.meta.keepAlive"
    ></router-view>
    <make-footer></make-footer>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
export default {
  created() {
    this.getConfig();
  },
  data() {
    return {
      /**
       * @description 获取配置信息
       * @return void
       */
      getConfig() {
        this.$api.configApi().then((res) => {
          this.SET_CONFILG(res.data);
        });
      },
    };
  },
  methods: {
    ...mapMutations(["SET_CONFILG"]),
    ...mapActions(["EXIT_LOGIN"]),
  },
};
</script>
<style lang="scss">
.el-checkbox {
  color: $-color-lighter;
  display: flex;
  align-items: center;

  .el-checkbox__input {
    border-color: $-color-lighter;

    &.is-checked,
    &.is-focus {
      border-color: $-color-primary;
    }
  }

  .el-checkbox__inner {
    &:hover {
      border-color: $-color-primary;
    }
  }
}
</style>
