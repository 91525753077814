import request from "@/utils/request";
export default {
  // 主体信息
  commonDataApi: () => {
    return request({
      url: "/pc/commonData",
      method: "get",
    });
  },
  // 全局配置
  configApi: () => {
    return request({
      url: "/index/config",
      method: "get",
    });
  },
  // 首页楼层列表
  floorGoodsListApi: () => {
    return request({
      url: "/pc/floor_goods_list",
      method: "get",
    });
  },
  // 帮助中心分类
  gethelpCategoryListApi: (params) => {
    return request({
      url: "/help/category",
      method: "get",
      params,
    });
  },
  // 帮助中心列表
  getHelpListApi: (params) => {
    return request({
      url: "/help/lists",
      method: "get",
      params,
    });
  },
  // 帮助中心详情
  getHepDetailApi: (params) => {
    return request({
      url: "/help/detail",
      method: "get",
      params,
    });
  },

  // 获取PC菜单
  getCategoryApi: () => {
    return request({
      url: "/pc/category",
      method: "get",
    });
  },
  // 获取广告列表
  getAdListApi: (params) => {
    return request({
      url: "/ad/lists",
      method: "get",
      params: {
        ...params,
        terminal: 2,
      },
    });
  },
  // 获取首页内容
  getIndexDataApi: (params) => {
    return request({
      url: "/index/index",
      method: "get",
      params: {
        ...params,
        terminal: 2,
      },
    });
  },
  // 获取首页热门关键词
  getSearchListApi: () => {
    return request({
      url: "search_record/lists",
      method: "get",
    });
  },
  //提交公司资质
  addCompanyQualificationApi: (data) => {
    return request({
      url: "account/addCompanyQualification",
      method: "post",
      data,
    });
  },
  //消息中心
  getNoticeApi: () => {
    return request({
      url: "notice/index",
      method: "post",
    });
  },
  // 获取历史浏览记录顺序
  getSideHistoryListsApi: (params) => {
    return request({
      url: "browsing_history/getSideHistoryLists",
      method: "get",
      params,
    });
  },
  // 获取历史浏览记录分日期
  getHistoryListsApi: (params) => {
    return request({
      url: "browsing_history/getHistoryLists",
      method: "get",
      params,
    });
  },

  // 删除历史记录
  deleteHistoryByIdArrayApi: (data) => {
    return request({
      url: "browsing_history/deleteHistoryByIdArray",
      method: "post",
      data,
    });
  },

  // 获取产品定制
  getCustomContentApi: (params) => {
    return request({
      url: "custom/getCustomContent",
      method: "get",
      params,
    });
  },

  // 获取专属商城分类列表
  getBuyCategoryListApi: (params) => {
    return request({
      url: "SpecialBuy/getCategoryList",
      method: "get",
      params,
    });
  },
  // 获取专属商城分类商品列表
  getBuyGoodsListApi: (params) => {
    return request({
      url: "SpecialBuy/getGoodsList",
      method: "get",
      params,
    });
  },
  // 获取专属商城推荐列表
  getBuyRecommendGoodsListApi: (params) => {
    return request({
      url: "SpecialBuy/getRecommendGoodsList",
      method: "get",
      params,
    });
  },
  // 获取专属商城品牌榜单
  getBuyGoodsBrandListApi: (params) => {
    return request({
      url: "SpecialBuy/getGoodsBrandList",
      method: "get",
      params,
    });
  },
  // 商品（收藏/取消）
  changeStatusApi: (data) => {
    return request({
      url: "goods_collect/changeStatus",
      method: "post",
      data,
    });
  },
  // 商品收藏列表
  goodsCollectListsApi: (data) => {
    return request({
      url: "goods_collect/lists",
      method: "get",
      data,
    });
  },

  // 获取秒杀时段
  getSeckillTimeApi: (data) => {
    return request({
      url: "SeckillGoods/getSeckillTime",
      method: "get",
      data,
    });
  },
  // 获取秒杀商品列表
  getSeckillListApi: (params) => {
    return request({
      url: "SeckillGoods/getSeckillGoods",
      method: "get",
      params,
    });
  },
  // 满减活动
  getReductListApi: (params) => {
    return request({
      url: "goods/getReductList",
      method: "get",
      params,
    });
  },
  // 满减活动商品
  getReductGoodstApi: (params) => {
    return request({
      url: "goods/getReductGoods",
      method: "get",
      params,
    });
  },
  // 所有省市区
  getAllAdressListApi: (params) => {
    return request({
      url: "UserAddress/all_address",
      method: "get",
      params,
    });
  },
};
