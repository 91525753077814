<template>
  <!-- 轮播 -->
  <article class="banner">
    <div
      v-if="banner || adList.lenght == 1"
      class="banner-img"
      :style="{
        backgroundImage: `url(${banner ? banner : adList[0].image})`,
        height: height + 'px',
      }"
    >
      <slot></slot>
    </div>
    <swiper
      v-if="adList.length > 0"
      :options="swiperOption"
      class="swiper"
      :style="{ height: height + 'px' }"
    >
      <!-- 轮播的图片 -->
      <swiper-slide
        class="pro-item bg-white"
        v-for="(item, index) in adList"
        :key="index"
        :style="`backgroundImage:url(${item.image})`"
      >
      </swiper-slide>
      <!-- 左右导航栏 -->
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- <el-carousel>
      <el-carousel-item v-for="item in adList" :key="item">
        <div
          class="banner-img"
          :style="`background-image: url(${item.image});`"
        >
          <slot></slot>
        </div>
      </el-carousel-item>
    </el-carousel> -->
  </article>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "makebanner",
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    // 广告高度
    height: {
      type: [Number, String],
    },
    // 是否单张banner
    banner: {
      type: String,
      default: "",
    },
    // 广告ID
    id: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      adList: [], //广告列表
      swiperOption: {
        grabCursor: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        speed: 600,
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  methods: {
    /**
     * @description 获取广告列表
     * @return void
     */
    getAdList() {
      this.$api
        .getAdListApi({
          pid: this.id,
        })
        .then((res) => {
          this.adList = res.data;
        });
    },
  },
  created() {
    this.getAdList();
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  object-fit: contain;
  .banner-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .pro-item {
    background-size: cover;
    background-position: center;
  }
  /deep/ .swiper {
    .swiper-button-next,
    .swiper-button-prev {
      width: 29px;
      height: 56px;
      background-size: 29px 56px;
      background-image: url("~@/assets/icon/icon-arrow3.png");
      margin-top: 0;
      right: 0;
      margin-top: -13px;
      opacity: 0.3;
      &:hover {
        opacity: 0.8;
      }
    }
    .swiper-button-prev {
      transform: rotate(180deg);
      left: 0;
    }
    .swiper-pagination-bullet {
      width: 40px;
      height: 10px;
      border-radius: 4px;
      margin: 0 6px;
      &.swiper-pagination-bullet-active {
        background-color: rgba($color: $-color-primary, $alpha: 0.9);
      }
    }
  }
}
</style>
