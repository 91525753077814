import request from "@/utils/request";
export default {
  // 添加购物车
  addCartApi: (data) => {
    return request({
      url: "/cart/add",
      method: "post",
      data,
    });
  },
  // 获取当前商品id
  getGoodsIdApi: (data) => {
    return request({
      url: "/cart/getGoodsId",
      method: "post",
      data,
    });
  },
  // 购物车列表
  getCartListApi: (data) => {
    return request({
      url: "/cart/lists",
      method: "post",
      data,
    });
  },
  // 删除购物车
  delCartApi: (data) => {
    return request({
      url: "/cart/del",
      method: "post",
      data,
    });
  },
  // 购物车更改选中状态
  setCartSelectedApi: (data) => {
    return request({
      url: "/cart/selected",
      method: "post",
      data,
    });
  },
  // 购物车更改商品数量
  setCartChangeNumApi: (data) => {
    return request({
      url: "/cart/change",
      method: "post",
      data,
    });
  },
  // 设置结算单
  setTlementApi: (data) => {
    return request({
      url: "/order/settlement",
      method: "post",
      data,
    });
  },
  // 购物车下单
  submitOrderApi: (data) => {
    return request({
      url: "/order/submit_order",
      method: "post",
      data,
    });
  },
  // 获取支付方式
  getPayWayApi: () => {
    return request({
      url: "/order/getPayWay",
      method: "get",
    });
  },
  // 获取支付方式
  getPayWayApi: () => {
    return request({
      url: "/order/getPayWay",
      method: "get",
    });
  },
  // 获取商品详情
  getGoodsDetailApi: (params) => {
    return request({
      url: "/goods/getGoodsDetail",
      method: "get",
      params,
    });
  },
  // 收藏/取消收藏
  setSearchRecordApi: (params) => {
    return request({
      url: "/search_record/lists",
      method: "get",
      params,
    });
  },
  // 获取商品评论计数
  getCommentCategoryApi: (params) => {
    return request({
      url: "/goods_comment/category",
      method: "get",
      params,
    });
  },
  // 获取商品评论
  getCommentListApi: (params) => {
    return request({
      url: "/goods_comment/lists",
      method: "get",
      params,
    });
  },
  // 获取产品列表（筛选）
  getGoodsListApi: (params) => {
    return request({
      url: "goods/getGoodsList",
      method: "get",
      params,
    });
  },
  // 获取热门/精品推荐列表
  getGoodsListByColumnIdApi: (params) => {
    return request({
      url: "goods/getGoodsListByColumnId",
      method: "get",
      params,
    });
  },
  // 获取订单列表
  getOrderListApi: (params) => {
    return request({
      url: "order/lists",
      method: "get",
      params,
    });
  },
  // 订单采购经理审核
  setManagerExamineApi: (data) => {
    return request({
      url: "order/manager_examine",
      method: "post",
      data,
    });
  },
  // 订单财务审核
  setFinanceExamineApi: (data) => {
    return request({
      url: "order/finance_examine",
      method: "post",
      data,
    });
  },
  // 订单详情
  getOrderDetailApi: (params) => {
    return request({
      url: "order/getOrderDetail",
      method: "get",
      params,
    });
  },
  // 支付订单
  unifiedpayApi: (data) => {
    return request({
      url: "pay/unifiedpay",
      method: "post",
      data,
    });
  },
  // 获取订单信息
  getPayStatusApi: (params) => {
    return request({
      url: "order/getPayStatus",
      method: "get",
      params,
    });
  },
  // 取消订单
  cancelOrderApi: (data) => {
    return request({
      url: "order/cancel",
      method: "post",
      data,
    });
  },
  // 删除订单
  delOrderApi: (data) => {
    return request({
      url: "order/del",
      method: "post",
      data,
    });
  },
  // 确认收货
  confirmOrderApi: (data) => {
    return request({
      url: "order/confirm",
      method: "post",
      data,
    });
  },
  // 查看物流
  getOrderTracesApi: (params) => {
    return request({
      url: "order/orderTraces",
      method: "get",
      params,
    });
  },
  // 获取评价商品
  getCommentPageApi: (params) => {
    return request({
      url: "goods_comment/getCommentPage",
      method: "get",
      params,
    });
  },
  // 添加商品评价
  addGoodsCommentApi: (data) => {
    return request({
      url: "goods_comment/addGoodsComment",
      method: "post",
      data,
    });
  },
  // 获取评价列表
  getUnCommentOrderApi: (params) => {
    return request({
      url: "goods_comment/getUnCommentOrder",
      method: "get",
      params,
    });
  },
  // 获取未评价列表
  getCommentOrderApi: (params) => {
    return request({
      url: "goods_comment/getCommentOrder",
      method: "get",
      params,
    });
  },
  // 获取售后列表
  getAfterSaleListApi: (params) => {
    return request({
      url: "after_sale/lists",
      method: "get",
      params,
    });
  },
  // 获取售后商品信息
  getAfterSaleGoodsInfoApi: (params) => {
    return request({
      url: "after_sale/goodsInfo",
      method: "get",
      params,
    });
  },
  // 新增退款
  addAfterSaleApi: (data) => {
    return request({
      url: "after_sale/add",
      method: "post",
      data,
    });
  },
  // 售后确认收货
  receiveAfterSaleApi: (data) => {
    return request({
      url: "after_sale/receive",
      method: "post",
      data,
    });
  },
  // 新增退款(整单)
  addAfterSaleOrderApi: (data) => {
    return request({
      url: "after_sale/order_add",
      method: "post",
      data,
    });
  },
  // 退款需要平台介入
  refundNeedDevApi: (data) => {
    return request({
      url: "order/refund_need_dev",
      method: "post",
      data,
    });
  },
  // 撤销申请
  cancelAfterSaleApi: (data) => {
    return request({
      url: "after_sale/cancel",
      method: "post",
      data,
    });
  },
  // 售后订单详情
  getAfterSaleDetailApi: (params) => {
    return request({
      url: "after_sale/detail",
      method: "get",
      params,
    });
  },
  // 售后订单填写快递信息内容
  addExpressApi: (data) => {
    return request({
      url: "after_sale/express",
      method: "post",
      data,
    });
  },
  // 获取订单状态
  getPayResultApi: (params) => {
    return request({
      url: "order/pay_result",
      method: "get",
      params,
    });
  },
  // 获取订单状态
  getXxPayApi: (data) => {
    return request({
      url: "order/update_offline_pay",
      method: "post",
      data,
    });
  },
  // 查看审批流程
  getOrderApprovalProcessApi: (params) => {
    return request({
      url: "order/orderApprovalProcess",
      method: "get",
      params,
    });
  },

  /**20240110  owen 湘雅定制需求*/

  // 添加采购临时清单
  addProcureTempApi: (data) => {
    return request({
      url: "/procureTemps/add",
      method: "post",
      data,
    });
  },
  // 采购临时清单列表
  getProcureTempListApi: (data) => {
    return request({
      url: "procureTemps/lists",
      method: "post",
      data,
    });
  },
  // 删除采购临时清单
  delProcureTempApi: (data) => {
    return request({
      url: "/procureTemps/del",
      method: "post",
      data,
    });
  },
  // 采购临时清单更改选中状态
  setProcureTempSelectedApi: (data) => {
    return request({
      url: "/procureTemps/selected",
      method: "post",
      data,
    });
  },
  // 采购临时清单更改商品数量
  setProcureTempChangeNumApi: (data) => {
    return request({
      url: "/procureTemps/change",
      method: "post",
      data,
    });
  },


  // 湘雅采购清单表  
  setTlementApi: (data) => {
    return request({
      url: "/order/settlement",
      method: "post",
      data,
    });
  },



  // // 采购清单确认添加到购物车
  // submitCreatedProcureApi: (data) => {
  //   return request({
  //     url: "procureCarts/created_procure",
  //     method: "post",
  //     data,
  //   });
  // },

  // 购物车列表
  getPlanCartListApi: (data) => {
    return request({
      url: "/procureCarts/lists",
      method: "post",
      data,
    });
  },


  // 删除湘雅购物车
  delProcureCartApi: (data) => {
    return request({
      url: "/ProcureCarts/del",
      method: "post",
      data,
    });
  },

  // 湘雅购物车更改选中状态
  setPlanCartSelectedApi: (data) => {
    return request({
      url: "/ProcureCarts/selected",
      method: "post",
      data,
    });
  },


  // 湘雅购物车更改商品数量
  setPlanCartChangeNumApi: (data) => {
    return request({
      url: "/ProcureCarts/change",
      method: "post",
      data,
    });
  },


  // 湘雅设置结算单
  setPlanTlementApi: (data) => {
    return request({
      url: "/ProcureCarts/settlement",
      method: "post",
      data,
    });
  },



  // 湘雅购物车更改送货日期
  setPlanCartChangeArrivalDateApi: (data) => {
    return request({
      url: "/ProcureCarts/change_arrival_date",
      method: "post",
      data,
    });
  },



  // 湘雅采购计划更改商品数量
  setPlanCartExamineNumApi: (data) => {
    return request({
      url: "/ProcureCarts/examine_change",
      method: "post",
      data,
    });
  },



   // 采购计划部门审核
   setDepartmentExamineApi: (data) => {
    return request({
      url: "ProcureCarts/department_examine",
      method: "post",
      data,
    });
  },
  // 采购计划后倾审核
  setLogisticsExamineApi: (data) => {
    return request({
      url: "ProcureCarts/logistics_examine",
      method: "post",
      data,
    });
  },
  /**20240110  owen 湘雅定制需求*/

  
  // 2024-02-29 isea excel导入，批量创建订单，保存  - 30分钟
  createByExcelUrl: (data) => {
    return request({
      url: "OrderData/orderImport",
      method: "post",
      data: {
        "url": data.url
      },
      time: 1800000
    });
  },
};
